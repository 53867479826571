#scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(95, 95, 95, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#scroll::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f500;
}

@media screen and (max-width: 600px) {
  #scroll::-webkit-scrollbar {
    display: none;
    width: 2px;
    background-color: #fffdfd00;
  }
}

#scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.14, rgb(255, 0, 0)),
    color-stop(0.62, rgb(254, 0, 0)),
    color-stop(0.86, rgb(255, 0, 0))
  );
}

.navbar-logo {
  object-fit: contain;
}

.footer-logo {
  width: 100%;
}

.typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #666666;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #666666;
  }
  28% {
    transform: translateY(-7px);
    background-color: #9a9a9a;
  }
  44% {
    transform: translateY(0px);
    background-color: #b9b9b9;
  }
}

.loading {
  position: relative;
  background-color: #e2e2e2;
}

.loading::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.6),
    transparent
  );
  animation: loading 1.5s infinite;
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.spinner {
  width: 19.2px;
  height: 19.2px;
  animation: spinner-y0fdc1 2.4s infinite ease;
  transform-style: preserve-3d;
}

.spinner > div {
  background-color: rgba(78, 39, 128, 0.2);
  height: 100%;
  position: absolute;
  width: 100%;
  border: 1px solid #4e2780;
}

.spinner div:nth-of-type(1) {
  transform: translateZ(-9.6px) rotateY(180deg);
}

.spinner div:nth-of-type(2) {
  transform: rotateY(-270deg) translateX(50%);
  transform-origin: top right;
}

.spinner div:nth-of-type(3) {
  transform: rotateY(270deg) translateX(-50%);
  transform-origin: center left;
}

.spinner div:nth-of-type(4) {
  transform: rotateX(90deg) translateY(-50%);
  transform-origin: top center;
}

.spinner div:nth-of-type(5) {
  transform: rotateX(-90deg) translateY(50%);
  transform-origin: bottom center;
}

.spinner div:nth-of-type(6) {
  transform: translateZ(9.6px);
}

@keyframes spinner-y0fdc1 {
  0% {
    transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
  }

  50% {
    transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
  }

  100% {
    transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
  }
}

.price {
  background:
    repeating-linear-gradient(
      45deg,
      #0000 calc(-650% / 13) calc(50% / 13),
      #431376 0 calc(100% / 13),
      #0000 0 calc(150% / 13),
      #431376 0 calc(200% / 13),
      #0000 0 calc(250% / 13),
      #431376 0 calc(300% / 13)
    ),
    repeating-linear-gradient(
        45deg,
        #0000 calc(-650% / 13) calc(50% / 13),
        #431376 0 calc(100% / 13),
        #0000 0 calc(150% / 13),
        #431376 0 calc(200% / 13),
        #0000 0 calc(250% / 13),
        #431376 0 calc(300% / 13)
      )
      32px 32px,
    repeating-linear-gradient(
      -45deg,
      #0000 calc(-650% / 13) calc(50% / 13),
      #431376 0 calc(100% / 13),
      #0000 0 calc(150% / 13),
      #431376 0 calc(200% / 13),
      #0000 0 calc(250% / 13),
      #431376 0 calc(300% / 13)
    ),
    repeating-linear-gradient(
        -45deg,
        #0000 calc(-650% / 13) calc(50% / 13),
        #431376 0 calc(100% / 13),
        #0000 0 calc(150% / 13),
        #431376 0 calc(200% / 13),
        #0000 0 calc(250% / 13),
        #431376 0 calc(300% / 13)
      )
      32px 32px #dec7f7;
  background-size: 64px 64px;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(95, 95, 95, 0.3);
  background-color: #ffffff00;
  border-radius: 10px;
}

.scroll::-webkit-scrollbar {
  width: 0px;
  background-color: #ffffff00;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.34, #ffffff),
    color-stop(0.62, #ffffff),
    color-stop(0.86, #ffffff)
  );
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(95, 95, 95, 0.3);
  background-color: #ffffff00;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 0px;
  background-color: #ffffff00;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.34, #ffffff),
    color-stop(0.62, #ffffff),
    color-stop(0.86, #ffffff)
  );
}
