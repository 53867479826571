#style-7::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(95, 95, 95, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#style-7::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

#style-7::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.14, rgb(2, 2, 2)),
    color-stop(0.62, rgb(1, 1, 1)),
    color-stop(0.86, rgb(0, 0, 0))
  );
}

.spinner {
  width: 19.2px;
  height: 19.2px;
  animation: spinner-y0fdc1 2.4s infinite ease;
  transform-style: preserve-3d;
}

.spinner > div {
  background-color: rgba(78, 39, 128, 0.2);
  height: 100%;
  position: absolute;
  width: 100%;
  border: 1px solid #4e2780;
}

.spinner div:nth-of-type(1) {
  transform: translateZ(-9.6px) rotateY(180deg);
}

.spinner div:nth-of-type(2) {
  transform: rotateY(-270deg) translateX(50%);
  transform-origin: top right;
}

.spinner div:nth-of-type(3) {
  transform: rotateY(270deg) translateX(-50%);
  transform-origin: center left;
}

.spinner div:nth-of-type(4) {
  transform: rotateX(90deg) translateY(-50%);
  transform-origin: top center;
}

.spinner div:nth-of-type(5) {
  transform: rotateX(-90deg) translateY(50%);
  transform-origin: bottom center;
}

.spinner div:nth-of-type(6) {
  transform: translateZ(9.6px);
}

@keyframes spinner-y0fdc1 {
  0% {
    transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
  }

  50% {
    transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
  }

  100% {
    transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
  }
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(95, 95, 95, 0.3);
  background-color: #ffffff00;
  border-radius: 10px;
}

.scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #ffffff00;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.34, #ffffff),
    color-stop(0.62, #ffffff),
    color-stop(0.86, #ffffff)
  );
}


@media screen and (min-width: 768px) { /* 768px is a common breakpoint for tablets */
  .sidetab {
    max-height: calc(100vh - 48px);
  }
}