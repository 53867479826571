.cs-main-container * {
  font-family: "Inter", sans-serif !important;
}
.cs-message__content {
  border-radius: 10px !important;
  border: solid 1px #ccc;
}

.cs-message--incoming .cs-message__content {
  background-color: #f5f5f5 !important;
}

.cs-message--outgoing .cs-message__content {
  background-color: rgb(243, 235, 252) !important;
}

.cs-conversation-header,
.cs-conversation-header__content .cs-conversation-header__user-name,
.cs-conversation-header__content .cs-conversation-header__info {
  background-color: white;
}

.cs-conversation.cs-conversation--active,
.cs-conversation:hover {
  background-color: rgba(243, 235, 252, 0.6) !important;
  color: #000;
  border-bottom: 1px solid rgb(243, 235, 252);
}

.ps__thumb-y {
  background-color: #ccc !important;
}

.cs-main-container > .cs-sidebar.cs-sidebar--left .cs-search {
  margin: 0 !important;
  border-radius: 0;
  background-color: #fff !important;
  border-bottom: solid 1px #000;

  .cs-search__search-icon {
    color: #000;
  }

  .cs-search__input {
    background-color: transparent;
  }
}

.cs-message-input {
  background-color: rgb(243, 235, 252) !important;

  .cs-message-input__content-editor-wrapper {
    border-radius: 0;
    background-color: transparent;

    .cs-message-input__content-editor-container,
    .cs-message-input__content-editor {
      background-color: transparent;
    }
  }
}

.cs-button.cs-button--send,
.cs-button.cs-button--arrow {
  color: #000 !important;
}

.cs-overlay::before {
  background-color: rgba(0, 0, 0, 0.218);
}

.cs-loader::before {
  border-color: #ccc !important;
}

.cs-loader::after {
  border-color: #0f172a transparent transparent transparent !important;
}

.cs-conversation {
  gap: 10px;
}

.right-sidebar-hidden {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .cs-main-container.show-left-sidebar .cs-chat-container {
    display: none !important;
  }

  .cs-main-container--responsive > .cs-sidebar.cs-sidebar--left {
    display: block !important;
    flex-basis: 100%;
    max-width: 100%;
  }

  .cs-search {
    display: flex !important;
  }

  .cs-main-container--responsive
    .cs-conversation-list
    .cs-conversation__content,
  .cs-main-container--responsive
    .cs-sidebar
    .cs-conversation-list
    .cs-conversation__content {
    display: block !important;
  }

  .cs-main-container--responsive:has(
      .cs-chat-container:not(.empty-chat-container)
    )
    .scrollbar-container.cs-sidebar.cs-sidebar--left {
    display: none !important;
  }

  .cs-conversation-header {
    display: flex !important;
  }

  .cs-chat-container
    .cs-message-input
    .cs-message-input__content-editor-wrapper:first-child {
    margin: 0 !important;
  }

  .cs-sidebar--right:not(.right-sidebar-hidden) {
    border: 0 !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    flex-basis: 100% !important;
    max-width: 100% !important;
    display: block !important;
  }
}
